import React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import Header from '../Header/Header'
import TextField from '@mui/material/TextField'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import Login from './Login';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './auth.css';
import AuthContext from './Auth-context';
import { auth, provider } from './config';
import { signInWithPopup } from 'firebase/auth';
import Footer from '../Home/Footer';


const Signup = () => {
  const history = useNavigate();
  const [screenheight, setscreenheight] = useState();
  const [visibleStatus, setvisibleStatus] = useState(false);
  const [googlelogin, setgooglelogin] = useState();
  const [isLoginActive, setLoginActive] = useState(false);
  const [isSignupLoading, setSignupLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  
 

  useEffect(() => {
    setscreenheight(window.innerHeight - 150 +'px')
     console.log(window.innerHeight);
  }, [])

 const passwordvisibleHandlder = (status) => {
  
   console.log(status);
   if(status === 'off'){
    setvisibleStatus(true);
   } else {
    setvisibleStatus(false);
   }
 }

 const googleLogin = () => {
  signInWithPopup(auth, provider).then((data) => {
    console.log(data);
    if(data) {
      setgooglelogin(data.user.email);
      localStorage.setItem('email', data.user.email);
      localStorage.setItem('idtoken', data.user.accessToken);
      authCtx.login(data.user.accessToken, data.user.email, data.user.displayName);
      
      history('/dashboard');
    }

  }).catch(err=>{
     alert(err.message);
}) 
 }

const signUphandler = (status) => {
  setSignupLoading(status);
  console.log(status);
  // setLoginActive(status);
  let userName = document.getElementById('filled-basic').value;
  let userPassword = document.getElementById('filled-password-input').value;
  console.log(userName, userPassword);
  let emailValidate = userName.trim();
  const minLength = 8;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const emailPattern2 = /^[a-zA-Z0-9._-]+@cave\.digital$/;
  if(emailValidate === ''){
    alert('Please Enter Email id');
    setSignupLoading(false);
    return false;
  } 
  // else if(!emailPattern.test(emailValidate)) {
  //   alert("Invalid Email address");
  //   setSignupLoading(false);
    
  // } 
  // else if(!emailPattern2.test(emailValidate)){
  //   alert("Invalid Email address two");
  //   setSignupLoading(false);
  //   return false;
  // } 
  // else {
  //   alert('Email id already exist please login or Please enter the valid Email id');
  //   setSignupLoading(false);
  //   return false;
  // }
  if(userPassword.length < minLength) {
    alert('Password must be at least '+ minLength +' characters long');
    setSignupLoading(false);
    return false;
  } else {

  }

  fetch('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w', {
    method: 'POST',
    body: JSON.stringify({
      email: userName,
      password:userPassword,
      returnSecureToken: true
    }),
    headers: {
      'content-Type': 'application/json'
    }
  }).then((res) => {
    console.log(res);
    if(res.ok){
      setSignupLoading(false);
      return res.json();
      
    } else {
     return res.json().then((data) => {
        console.log(data);
        setSignupLoading(false);
        alert(data.error.message)
        console.log(data.error.message);
      });
    }
  }).then((data) => {
    console.log(data);
    if(data){
      authCtx.login(data.idToken);
      localStorage.setItem('email', data.email);
      localStorage.setItem('idtoken', data.idToken);
      history('/dashboard');
    }    
  }).catch(err => {
    alert(err.message);
    console.log(err.message);
  });
  };
 
 

  return (
    <div>
      <Header/>
      <CssBaseline />
      {screenheight ?   <div className='signupcontainer' style={{height: screenheight}}>
      <Container maxWidth="md">
      <Grid container spacing={2}>
      <Grid xs={12} md={6}>
      <h2>Sign up</h2>
      <div className='InputField'>
      <Button className='googleLoginsvG' size="large" onClick={googleLogin} variant="outlined" style={{textTransform: "inherit", width: '100%', color: '#000000', fontWeight: '700', border: '1px solid #cacaca'}}><svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className="MfSWZ05" aria-hidden="true"><g fill="none"><path d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4"></path><path d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 009 18z" fill="#34A853"></path><path d="M3.964 10.71A5.41 5.41 0 013.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 000 9c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05"></path><path d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 00.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#EA4335"></path><path d="M0 0h18v18H0z"></path></g></svg> Continue with Google</Button>
      </div>
      <div className='InputField'>
      <TextField className='fieldCS'  id="filled-basic" label="Email" variant="filled" />
      </div>
      <div className='InputField'>
      <TextField 
      className='fieldCS'
      id="filled-password-input"
      label="Password"
      type={visibleStatus ? 'text': 'password'}
      autoComplete="current-password"
      variant="filled"
      
    >
   
    </TextField>
    <span className='passvisiblePass' style={{cursor: 'pointer'}}>{visibleStatus ? <VisibilityIcon onClick={()=> passwordvisibleHandlder('on')} sx={{color: '#5c5c5c'}}/> : <VisibilityOffIcon onClick={()=> passwordvisibleHandlder('off')} sx={{color: '#5c5c5c'}} />}</span>
      </div>
    {isSignupLoading ? <h4>Sending request</h4> : <Button variant="contained" onClick={()=> signUphandler(true)} style={{textTransform: "inherit", width: '100%', background: '#2791f7'}}>Signup with Email</Button>}  
      <small>By continuing with Google, Apple, or Email, you agree to taskticks <a href='#'>Terms of Service</a> and <a href='#'>Privacy Policy</a>.</small>
      <h4>Already signed up? <Link to='/auth/login'>Go to login</Link></h4>
      </Grid>
      <Grid xs={12} md={6}>
      <img className='imageWidth' src='../images/authsideimagesinup.jpg' />
      </Grid>
      </Grid>
      </Container>
      </div> : ''}
      
      <Footer/>
    </div>
  )
}

export default Signup

import React from 'react';
import { useEffect, useState, useContext } from 'react';
import Header from '../Header/Header'
import TextField from '@mui/material/TextField'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import { auth, provider } from './config';
import { signInWithPopup } from 'firebase/auth';
 
import { Link, useNavigate } from 'react-router-dom';
import './auth.css'
import AuthContext from './Auth-context';
 
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Footer from '../Home/Footer';


const Login = () => {
  const history = useNavigate();
  const [screenheight, setscreenheight] = useState();
  const [googlelogin, setgooglelogin] = useState();
  const [visibleStatus, setvisibleStatus] = useState(false);
  const [isLoginActive, setLoginActive] = useState(false);
  const [isLoginLoading, setLoginLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    setscreenheight(window.innerHeight - 150 +'px')
     console.log(window.innerHeight);
  }, [])
  
 const passwordvisibleHandlder = (status) => {
    console.log(status);
    if(status === 'off'){
     setvisibleStatus(true);
    } else {
     setvisibleStatus(false);
    }
  }
 const googleLogin = () => {
  signInWithPopup(auth, provider).then((data) => {
    console.log(data);
    if(data) {
      setgooglelogin(data.user.email);
      localStorage.setItem('email', data.user.email);
      localStorage.setItem('idtoken', data.user.accessToken);
      localStorage.setItem('username', data.user.displayName);
      console.log(data.user.accessToken, data.user.email, data.user.displayName);
      authCtx.login(data.user.accessToken, data.user.email, data.user.displayName);
      history('/dashboard');
      sendEmail(data.user.email, data.user.displayName, 'login');
    }

  }).catch(err=>{
     alert(err.message);
}) 
 }
  
//  useEffect(()=> {
//   setgooglelogin(localStorage.getItem('email'));
//  })

 const loginHandler = (status) => {
  setLoginLoading(status);
  console.log(status);
  // setLoginActive(status);
  let userName = document.getElementById('filled-basic').value;
  let userPassword = document.getElementById('filled-password-input').value;
  console.log(userName, userPassword);
 
  fetch('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w', {
    method: 'POST',
    body: JSON.stringify({
      email: userName,
      password:userPassword,
      returnSecureToken: true
    }),
    headers: {
      'content-Type': 'application/json'
    }
  }).then((res) => {
    console.log(res);
    if(res.ok){
      setLoginLoading(false);
      return res.json();
      
    } else {
     return res.json().then((data) => {
        console.log(data);
        setLoginLoading(false);
        alert(data.error.message)
      });
    }
  }).then((data) => {
    console.log(data);
    if(data) {
      data.displayName = data.displayName ? data.displayName :  data.email
      authCtx.login(data.idToken, data.email, data.displayName);
      localStorage.setItem('email', data.email);
      localStorage.setItem('idtoken', data.idToken);
      localStorage.setItem('username', data.displayName);
      history('/dashboard');
      
      sendEmail(data.email, data.displayName, 'login');
    }
    
 
  
    
  }).catch(err => {
    alert(err.message);
  });
  };


  const sendEmail = (email, UserName) => {
    console.log(email, UserName);
     let bodyInfo = "<p>Hello, " + UserName+ '<br> <br> Welcome to TaskTicks! Now you can maintain daily tasks follow ups with our Tool TaskTicks. <br><br>Thank you</p>';
    fetch('https://server.taskticks.com/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        subject: 'Welcome to TaskTicks',
        html: bodyInfo,
        which: "login"
      }),
    })
    // Its a Schedule Mail of TodoList Due task Notifications
      .then((response) => {
        if (response.status === 200) {
          console.log('Email sent successfully');
        } else {
          console.error('Error sending email');
        }
      })
      .catch((error) => {
        console.error('Network error:', error);
      });
  };


  return (
    <div>
      <Header/>
      <CssBaseline />
      {screenheight ?  <div className='Logincontainer' style={{height: screenheight}}>
      <Container maxWidth="md">
      <Grid container spacing={2}>
      <Grid xs={12} md={6}>
      <h2>Login</h2>
      <div className='InputField'>
      <Button className='googleLoginsvG' size="large" onClick={googleLogin} variant="outlined" style={{textTransform: "inherit", width: '100%', color: '#000000', fontWeight: '700', border: '1px solid #cacaca'}}><svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className="MfSWZ05" aria-hidden="true"><g fill="none"><path d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4"></path><path d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 009 18z" fill="#34A853"></path><path d="M3.964 10.71A5.41 5.41 0 013.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 000 9c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05"></path><path d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 00.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#EA4335"></path><path d="M0 0h18v18H0z"></path></g></svg> Continue with Google</Button>
      </div>
      <div className='InputField'>
      <TextField className='fieldCS' id="filled-basic" label="Email" variant="filled" />
      </div>
      <div className='InputField'>
      <TextField 
      className='fieldCS'
      id="filled-password-input"
      label="Password"
      type={visibleStatus ? 'text': 'password'}
      autoComplete="current-password"
      variant="filled"
    />
    <span className='passvisiblePass' style={{cursor: 'pointer'}}>{visibleStatus ? <VisibilityIcon onClick={()=> passwordvisibleHandlder('on')} sx={{color: '#5c5c5c'}}/> : <VisibilityOffIcon onClick={()=> passwordvisibleHandlder('off')} sx={{color: '#5c5c5c'}} />}</span>
      </div>
      
      <Button onClick={()=> loginHandler(true)} variant="contained" style={{textTransform: "inherit", width: '100%', background: '#2791f7'}}>Log in</Button>
      <div className='' style={{marginTop: '10px'}}>
      <small><Link style={{fontWeight: '900', textDecoration: 'none'}} to='/auth/password'>Forgot your password?</Link></small>
      </div>
      <small style={{marginTop: '10px'}}>By continuing with Google, Apple, or Email, you agree to taskticks <a href='#' style={{textDecoration: 'none'}}>Terms of Service</a> and <a href='#' style={{textDecoration: 'none'}}>Privacy Policy</a>.</small>
      <h4>Don’t have an account? <Link to='/auth/signup'>Sign up</Link></h4>
      </Grid>
      <Grid xs={12} md={6}>
       <img className='imageWidth' src='../images/authsideimage.jpg' alt='login'/>
      </Grid>
      </Grid>
      </Container>
      </div> : ''}
      <Footer/>
    </div>
  )
}

export default Login

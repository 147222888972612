import React from 'react';
import SideButton from './SideButton';
import Header from '../Header/Header'
import { DataGrid } from "@mui/x-data-grid";
import { dbSearch  } from '../../auth/config';
import { db } from '../../auth/config';
import { collection, getDocs, deleteDoc, doc, query, where, orderBy, updateDoc} from 'firebase/firestore';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import AuthContext from '../../auth/Auth-context';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';


const drawerWidth = 270;
const columns = [
    // { field: ' ', headerName: 'ID', width: 300 },
    { field: 'terms', headerName: 'Search Terms', width: 300 },
    { field: 'Date', headerName: 'Date', width: 300 },
    { field: 'Time', headerName: 'Time', width: 400 }
  ];

function Yrr_mynewtab_data(props) {
  const [open, setOpen] = React.useState(false);
  const searchCollectionRef = collection(dbSearch, "yrr-mynewtab.org");
  const inviteUserCollection = collection(db, "inviteUsers");
  const [searchTerms, setSearchTerms] = useState([]);
  const [filterTerms, setFilterTerms] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] =  useState([]);
  const [loadUser, setOnLoaderUser] = useState();
  const [updateID, setUpdateID] = useState();
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState([]);
  let storedArraySearchTerms = [];
  var arrayItems;

 
  const authCtx = useContext(AuthContext);
   console.log(authCtx)
    useEffect(()=> {
      var email = localStorage.getItem('email');
      setUpdateID(authCtx.email);
      setOpen(true);
      // getUserData();
    
        if(updateID){
          //alert("User Found Successfully");
          fetchItems();
        }
     
        
       
    }, [authCtx])

    // const getUserData = async()=> {
    //   const data = await getDocs(inviteUserCollection);
    //   console.log(data.docs)
    //   let dataArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
    //   if(updateID){
    //     var getUserID = dataArray.find(item => item.EmailId === updateID)
    //     console.log(dataArray, updateID, getUserID);
    //     getData(getUserID.id);
    //   }
    // }
    const fetchItems = async () => {
      const response = await axios.get('https://server.taskticks.com/api/items');
      if(response.data.length > 0){
       console.log(response.data);
      let dataArray = response.data;
      if(updateID){
        var getUserID = dataArray.find(item => item.Email === updateID)
        console.log(dataArray, updateID, getUserID);
        if(getUserID){
          getData(getUserID._id);
        } else {
          // console.log(olderID);
           arrayItems = {          
           Email : updateID,
           // yrr_mynewtab: storedArraySearchTerms
         };
         console.log(arrayItems)
         // setNewItem(arrayItems);
         //  console.log(newItem);
         createItem(arrayItems);
        
       }
      } 
     setItems(response.data);
      }  else {
        // console.log(olderID);
         arrayItems = {          
         Email : updateID,
         // yrr_mynewtab: storedArraySearchTerms
       };
       console.log(arrayItems)
       // setNewItem(arrayItems);
       //  console.log(newItem);
       if(!getUserID){
        createItem(arrayItems);
        fetchItems()
       }
      
     }
      
    };
    
   

    const isWithinLast24Hours = (dateToCheck) => {
      // Get the current date and time
      const currentDate = new Date();
    
      // Calculate the date and time 24 hours ago
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(currentDate.getHours() - 6); // Subtract 24 hours from current time
    
      // Convert dateToCheck to a Date object if it's not already
      const checkDate = new Date(dateToCheck);
    
      // Compare checkDate with the date and time 24 hours ago
      return checkDate >= twentyFourHoursAgo && checkDate <= currentDate;
    };

   


    const getData = async(UserID) => {
      let result;
     
      const response = await axios.get('https://server.taskticks.com/api/items');
      console.log(response.data);
      if(response.data.length > 0){
        let dataArray = response.data;
        var getUserID = dataArray.find(item => item._id === UserID);
       // console.log(getUserID.yrr_mynewtab);
       storedArraySearchTerms = getUserID.yrr_mynewtab;
       
      }
        

      let getPreviousDate = localStorage.getItem('yrr-mynewtab.org_Date');
      if(getPreviousDate){
        result = isWithinLast24Hours(getPreviousDate);
      }
      
      
      if (result) {
        // console.log(`${currentDate} is within the last 24 hours.`);
        // localStorage.removeItem('yrr_mynewtab_searchTerms');
        // localStorage.removeItem('yrr_mynewtab_Date');
      } else {
        // localStorage.removeItem('yrr_mynewtab_searchTerms');
        localStorage.removeItem('yrr-mynewtab.org_Date');
        const response = await axios.get('https://server.taskticks.com/api/items');
        // const data = await getDocs(inviteUserCollection);
      // console.log(data.docs)
      let dataArray = response.data;
      var getUserID = dataArray.find(item => item._id === UserID);
      // console.log(getUserID.mynewtab);
      storedArraySearchTerms = await getUserID.yrr_mynewtab;
        // storedArraySearchTerms = localStorage.getItem('mynewtab_searchTerms');
        getPreviousDate = localStorage.getItem('yrr-mynewtab.org_Date');
        // console.log(`${currentDate} is NOT within the last 24 hours.`);
      }
      
      

      
      if(storedArraySearchTerms && getPreviousDate){
      //  console.log(JSON.parse(storedArraySearchTerms));
        console.log(storedArraySearchTerms);
        storedArraySearchTerms = storedArraySearchTerms
        setSearchTerms(storedArraySearchTerms);
        setFilterTerms(storedArraySearchTerms);
        setOpen(false);
       
        // alert("LocalStorage");
      } else {
        // alert("From API")
        const data = await getDocs(searchCollectionRef);
        // console.log(data.docs)
        let dataArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        console.log(dataArray);
        if(dataArray.length === 0){
          alert("No Data Available")
          setOpen(false);
          return false;
        }
        const formattedDataArray = dataArray.map(item => {
          const timestamp = item.Date.seconds;
          // console.log(new Date(timestamp))
          const formattedDate = formatDate(timestamp);
          
          const dt = new Date(timestamp * 1000); // Convert seconds to milliseconds
          
          // console.log(dt)
         
          const hours = dt.getUTCHours().toString().padStart(2, '0'); // Get hours (in UTC) and pad with zero if needed
          const minutes = dt.getUTCMinutes().toString().padStart(2, '0'); // Get minutes (in UTC) and pad with zero if needed
          const seconds = dt.getUTCSeconds().toString().padStart(2, '0'); // Get seconds (in UTC) and pad with zero if needed
          const formattedTime = `${hours}:${minutes}:${seconds}`;
          return {
              ...item,
              "Date": formattedDate,
              "Time": convertTo12HourFormat(dt)
          };
      });
      const arrayStringPassToLocal = formattedDataArray;
      
      
      formattedDataArray.map((item, index) => {
          // console.log(item.Date, index)
          const isOlderThan2Days = isDateOlderThan2Days(item.Date);
          if (isOlderThan2Days) {
            // console.log(`The date ${item.Date} is older than the last 2 days.`);
            setOpen(true);
            
            // olderID = [item.id, ...olderID];
            
              //alert("hello")
              AutoDelFunc(item.id);
            
          } else {
            // setOpen(false);
            // console.log(`The date ${item.Date} is within the last 2 days or more recent.`);
          }
      })
      
      // Save the stringified array to localStorage under the key 'myArray'
     // const userDoc = doc(inviteUserCollection, UserID)
      //console.log(userDoc);
      setOpen(true);
      
     
      try{
        const response = await axios.put(`https://server.taskticks.com/api/items/${UserID}`, {yrr_mynewtab: arrayStringPassToLocal});
        setItems(items.map(item => (item._id === UserID ? response.data : item)));
      //   await updateDoc(userDoc, {
 
      //     // EmailId:  emailid,
      //     // Level: permissionValue,
      //     // SelectedID: permissionID,
      //     yrr_mynewtab: arrayStringPassToLocal 
      // })
      console.log("Updated Successfully");
      setOpen(false);
      } catch(error){
        console.error("Error updating document: ", error);
        setOpen(false);
      }
    
    // setNewItem(arrayStringPassToLocal)
      
      
     // localStorage.setItem('yrr_mynewtab_searchTerms', arrayStringPassToLocal);
      localStorage.setItem('yrr-mynewtab.org_Date', new Date());
      setSearchTerms(formattedDataArray);
      setFilterTerms(formattedDataArray)
      }
     
    
     
    }

 
    
    const AutoDelFunc = async(id) => {
      
      try {
            const userDoc = doc(dbSearch, 'yrr-mynewtab.org', id);
            setOpen(true);
            await deleteDoc(userDoc);
             console.log("Deleted Successfully");
            //  setOpen(false);
            } catch (error) {
              console.error("Error deleting document: ", error);
              setOpen(false);
            }
           
    }
     
    const isDateOlderThan2Days = (dateToCheck) => {
      // Get the current date
      const currentDate = new Date();
    
      // Calculate the date 2 days ago
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(currentDate.getDate() - 2); // Subtract 2 days from current date
    
      // Convert dateToCheck to a Date object if it's not already
      const checkDate = new Date(dateToCheck);
    
      // Compare checkDate with the date 2 days ago
      return checkDate < twoDaysAgo;
    };


    const convertTo12HourFormat = (utcDateString) => {
        // Create a Date object from the UTC date string
        const date = new Date(utcDateString);

        // Get the day of the week (e.g., "Mon", "Tue", etc.)
        const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' });
      
        // Get the month name (e.g., "Jan", "Feb", etc.)
        const month = date.toLocaleString('en-US', { month: 'short' });
      
        // Get the day of the month (e.g., "01", "02", etc.)
        const dayOfMonth = date.getDate();
      
        // Get the year (e.g., "2024")
        const year = date.getFullYear();
      
        // Get the hour in 12-hour format (e.g., "09", "10", etc.)
        const hours = date.toLocaleString('en-US', {hour12: true });
      
        // Get the minutes (e.g., "22", "30", etc.)
        const minutes = date.getMinutes();
      
        // Get the AM/PM indicator (e.g., "AM", "PM")
        const ampm = date.toLocaleString('en-US', { hour: 'numeric', hour12: true, hourCycle: 'h12' }).split(' ')[1];
      
        // Construct the formatted date string
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year} ${hours}`;
      
        return formattedDate;
    };
    


    
    const formatDate = (timestamp) => {
      const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
      const day = date.getDate();
      const month = date.getMonth() + 1; // Month is zero-based, so we add 1
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = ('0' + date.getMinutes()).slice(-2); // Ensure two-digit minutes
      const period = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM
      const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
  
      return `${month}/${day}/${year}`;
      // return `${month}/${day}/${year} ${formattedHours}.${minutes}${period}`;
  }



   const searchTermsFilter = async(event) => {
    console.log(event.currentTarget.value);
    let val = event.currentTarget.value; 


    if(val != ''){
      const latestDocuments = searchTerms.filter(item => item.terms.toLowerCase().replace(/\s/g, '').includes(val.replace(/\s/g, '')));
    //  setFilterTerms(latestDocuments);
      console.log(latestDocuments)
      if(latestDocuments.length === 0){

        setFilterTerms(latestDocuments);
      } else{
        setFilterTerms(latestDocuments);
      }
    } else{
      setFilterTerms(searchTerms);
    }
    
   }

//  const AutoDeleteFunction = async() => {
         
//  }
  
//  var storeID;
  
     

 
// const handleDeleteSelectedRows = async() => {
//   // Perform deletion logic for selected rows
//   // console.log('Selected Rows:', selectionModel);
//   // Example: Remove selected rows from the data source
//   // let decideArray = filterTerms.length > 0 ? filterTerms : searchTerms;
//   if(rowSelectionModel.length === 0){
//     alert("Please select atleast one row to delete")
//     return false
//   }
//   setOpen(true);
//   const confirmed = window.confirm('Are you sure you want to delete '+rowSelectionModel.length+' rows?');
//   if(confirmed){
//     console.log('Remaining Rows:', rowSelectionModel);
 
//     rowSelectionModel.map(async(id) => {
      
//         // Construct a reference to the document using its ID
//         const userDoc = doc(dbSearch, 'mynewtab', id);
         
//         // Delete the document using deleteDoc() function
//         await deleteDoc(userDoc);
  
//         console.log(`Document with ID ${id} successfully deleted.`);
        

//     })
//     getData();
//     setOpen(false);
//   }
  
  
    
   
//   // You can perform further actions based on the selected rows
// };

//  Auto Delete function






  useEffect(()=> {
    let decideArray = filterTerms.length > 0 ? filterTerms : searchTerms;
    const filtered = searchTerms.filter(item => {
      const itemDate = new Date(item.Date);
      let start = startDate ? new Date(startDate) : null;
      let end = endDate ? new Date(endDate) : null;
    
      // Reduce the start date by 1 day if it's defined
      if (start) {
        start.setUTCDate(start.getUTCDate() - 1);
      }
    
      // Increase the end date by 1 day if it's defined
      if (end) {
        end.setUTCDate(end.getUTCDate());
      }
    
      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
    
      return true; // If no date range specified, return all
    });
    if(startDate && endDate){
      // console.log(filtered.length)
    if(filtered.length === 0){
      alert('No Search Query found on selected dates'); 
      setStartDate('');
      setEndDate('');
    }
    }
    setFilterTerms(filtered);
  }, [endDate, startDate])
   
  const ClearDate = () => {
    setStartDate('');
    setEndDate('');
  }

  const createItem = async (newItem) => {
    console.log('mangoDB connectedPost', newItem);
    try{
      const response = await axios.post('https://server.taskticks.com/api/items', newItem);
      setItems([...items, response.data]);
    } catch (error) {
      console.error(error);
    }
    
    // setNewItem({ name: '', description: '' });
  };
 
  // console.log(filterTerms)
  return (
      <div><Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
       
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}

      >
        <Toolbar style={{background: '#fff'}}>
          <Typography  style={{color: '#333', fontWeight: 'bold'}} variant="h6" noWrap component="div">
            Analytics Tool 
          </Typography>
       
        </Toolbar>
      </AppBar>
      
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
          <div className='searchTermsDiv pt-1'>
          <h4><b>yrr-mynewtab.org Search Terms <b style={{float: 'right'}}>Total: {searchTerms ? searchTerms.length: ''}</b> </b></h4>
            
            <div className='filterSection' style={{marginTop: '20px', marginBottom: '10px'}}>
              
            <input style={{padding: '5px 10px 5px 10px', width: '300px', marginRight: '20px'}} type='search'  placeholder='Search' onChange={searchTermsFilter}/>
            {/* <Button onClick={handleDeleteSelectedRows}>Delete Selected Rows</Button> */}
            <div className='dateRange' style={{float: 'right'}}>
            <button onClick={ClearDate} className='btn btn-danger me-2'>Clear</button>
            From: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder='Search' style={{padding: '5px 10px 5px 10px', width: '150px', marginRight: '0px'}}/> To: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} placeholder='Search' style={{padding: '5px 10px 5px 10px', width: '150px', marginRight: '0px'}}/>
            </div>
            </div>
          <DataGrid 
            sx={{minHeight: '200px'}}
            rows={filterTerms && filterTerms}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // selectionModel={selectionModel}

            checkboxSelection
            checkboxSelectionVisible={true} // Ensure the "Select All" checkbox is visible
            // checkboxSelectionProps={{
            //   indeterminate: selectionModel.length > 0 && selectionModel.length < searchTerms.length,
            //   checked: selectionModel.length === searchTerms.length,
            //   onChange: (event) => handleSelectAll(event.target.checked),


            // }}
            // onSelectionModelChange={handleSelectionModelChange}
            // onRowSelectionModelChange={(newRowSelectionModel) => {
            //   setRowSelectionModel(newRowSelectionModel);
            // }}
          />
          </div>
        
      </Box>
    </Box>
          
          
      </div>
  );
}






export default Yrr_mynewtab_data;
import React from 'react';
import Header from '../Header/Header';
import HomeBody from './HomeBody';
import Footer from './Footer';

const Home = () => {
  return (
    <div>
    <Header></Header>
    <HomeBody></HomeBody>
    <Footer></Footer>
    </div>
  )
}

export default Home

import React, { useEffect, useState, useRef } from 'react'
import './Header.css';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { useContext } from 'react';
import AuthContext from '../../auth/Auth-context';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import {dbSearch} from "../../auth/config";
import { db } from "../../auth/config";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { IconButton } from '@mui/material';


const Header = () => {
 const history = useNavigate();
 const authctx = useContext(AuthContext)
 const [headerFix, setHeaderFix] = useState(false);
 const isUserloggedIn = authctx.isLoggedIn;
 const [MenuOpen, setMenuopen] = useState(false);
 const location = useLocation();
 const [searchValue, setSearchValue] = useState();
 const searchCollectionRef = collection(dbSearch, "taskticks");
 const inviteUserCollection = collection(db, "inviteUsers");
 const [widgetOpen, setWidgetOpen] = useState(false);
 const dropdownRef = useRef(null);
 let emailId;
 useEffect(() =>{
  emailId = localStorage.getItem('email');
  let idToken = localStorage.getItem('idtoken');
  console.log(idToken);
  idToken = idToken ? idToken : null;
  authctx.login(idToken);
  if(idToken) {
    getUserData(idToken);
  }
  
 },[])
 
 const postSearch = async(value, date) => {
  console.log(value, date);

  await addDoc(searchCollectionRef, {
   terms:  value,
   Date: date
  });
}

 const menuOpenHandler = (res)=> {
  setMenuopen(res)
}

const menuCloseHandler = (res)=> {
  setMenuopen(res)
}


useEffect(()=> {
  const pathname = location.pathname;

// Now you can use the pathname variable as needed
console.log('Current pathname:', pathname);
}, [])


useEffect(()=> {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const qValue = urlParams.get('q');
  setSearchValue(qValue);
  console.log(qValue);
  if(qValue) {
    SearchYahoo();
    // alert(qValue)
  }
}, [])
const enteredSearch = (e) => {
  setSearchValue(e.target.value);
  console.log(e.target.value);
}




 
 const logoutHandler = (status) => {
   console.log(status)
   authctx.logout();
   localStorage.setItem('email', "");
   localStorage.setItem('idtoken', "");
   history('/auth/login');
 }


 const getUserData = (token) => {
  fetch('https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w', {
    method: 'POST',
    body: JSON.stringify({
      idToken : token
    }),
    headers: {
      'content-Type': 'application/json'
    }
  }).then((res) => {
    console.log(res);
    if(res.ok){
      return res.json();
      
    } else {
     return res.json().then((data) => {
        console.log(data);
         console.log(data.error.message)
         authctx.login(null);
      });
    }
  }).then(async(data) => {
    console.log(data);  
    if(data.users) {
      history('/dashboard');
      // alert('login successfully')
    // const dataInvite = await getDocs(inviteUserCollection);
    // console.log(dataInvite.docs);
    // let dataArray = dataInvite.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    // console.log(dataArray);
    // console.log(data.users);
    // data.users.map((value, index)=> {
    //   dataArray.map((value2, index2)=> {
    //     if(value.email === emailId) {
    //       // authctx.email = value.email;
    //       // authctx.username = value.displayName;
    //       authctx.checkAcess = {
    //         Level: value2.Level,
    //         SitePermission: value2.SitePermission
    //       };
    //       // console.log(authctx, value2, value)
          
    //     }
    //   })
        

    // })
    console.log(authctx)
      // history('/dashboard');
    
      // console.log(authctx.email);
      //   authctx.email = data.users[0].email;
      //   authctx.username = data.users[0].displayName;
      // history('/dashboard');
    }
    
  }).catch(err => {

    alert(err.message);
    authctx.login(null);
    history('/auth/login');
  });
 }
 
  useEffect(()=> {
    scrollFunctionHeader();
    window.onscroll = function() {scrollFunctionHeader()};
  })
  function scrollFunctionHeader(){
    const pathname = location.pathname;
    if(document.documentElement.scrollTop >= '100'){
      if(pathname === '/about-us'){
        setHeaderFix(false) 
      } else {
        setHeaderFix(true) 
      }
      
    } else{
      setHeaderFix(false) 
    }
  }

  const SearchYahoo = (event) => {
  
    //  console.log(event);
       if(event) {
        if(event.key === 'Enter') {
          // console.log("enter works");
          let searchYahoo = document.getElementById('searchYahoo');
          // console.log(searchYahoo.value);
           let SYvalue = searchYahoo.value;
            var SearchedDate = new Date();
           postSearch(SYvalue, SearchedDate);
          // navigate('/search?q='+ SYvalue);
           // console.log(queryString);
           const url = `/search?q=${SYvalue}`;
            window.history.pushState(null, null, url);
            let passLink = 'https://search.yahoo.com/search?p='+ SYvalue;
          // console.log(passLink);
           setTimeout(() => {
            //  navigate(passLink, { replace: true });
             window.open(passLink, '_target', 'noreferrer');
           }, 500)
           
           
         }
        //  alert("if");
       }else {
       //  alert("else");
        // let searchYahoo = document.getElementById('searchYahoo');
        // // console.log(searchYahoo.value);
        //  let SYvalue = searchYahoo.value;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const qValue = urlParams.get('q');
        
        history('/search?q='+ qValue);
        let SearchedDate = new Date();
        // setSearchDate(Date);
        postSearch(qValue, SearchedDate);
         // console.log(queryString);
        //  return false;
         const url = `/search?q=${qValue}`;
          window.history.pushState(null, null, url);
          let passLink = 'https://search.yahoo.com/search?p='+ qValue;
        // console.log(passLink);
         setTimeout(() => {
          //  history(passLink, { replace: true });
           window.open(passLink, '_self', 'noreferrer');
         }, 500)
      }
       
      
    }
  
    const widgetOpenHandler = () => {
      setWidgetOpen(true);
    }
    const widgetCloseHandler = () => {
      setWidgetOpen(false);
    }

    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //   //  alert("hell")
    //     // widgetCloseHandler();
    //     if (widgetOpen) {
    //       widgetCloseHandler();
    //     }
    //   };
  
    //   if (widgetOpen) {
    //     document.addEventListener('mousedown', handleClickOutside);
    //   } else {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   }
  
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // });



  return (
    <div>
      <header>
       <div className={headerFix ? 'headparent headerFixed': 'headparent'}> 
       <div className='logoImage'>
       <span className='logoTxt'><a href='/'><img src='../images/Logo/logo.png' alt='TaskTicks'/></a></span> 
       </div>
       <span className='menuList'>
       <ul>
       <li>
       
       <input onKeyDown={SearchYahoo} className='searchYahooInput' type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' /> 
       </li>
      
       
       <li>
       <a href='/feature'>Features</a>
       </li>
       
       
       
       {isUserloggedIn ? <li onClick={()=> logoutHandler(true)} style={{cursor: 'pointer'}}>
        <b>Logout</b>
        </li> : <span><li>
        <a href='/auth/login'>Login</a>
        </li>
       <li>
       
       <a href='/auth/signup'><Button className='headerButton' variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important'}}>Start for free</Button></a>
       </li></span>}
       
       <li ref={dropdownRef}>{widgetOpen ? <IconButton onClick={()=> widgetCloseHandler(false)}  sx={{fontSize: '30px', color: '#cd5ba9!important'}}><CloseIcon sx={{fontSize: '30px'}}/></IconButton> : <IconButton onClick={()=> widgetOpenHandler(false)}  sx={{fontSize: '30px', color: '#cd5ba9!important'}}><WidgetsIcon sx={{fontSize: '30px'}}/></IconButton>}</li>
       </ul>
       {widgetOpen ? <div className='widgetDiv'> <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/about-us'>About Us</a></li>
          <li><a href='/contact-us'>Contact us</a></li>
          <li><a href='/terms-of-service'>Terms of service</a></li>
          <li><a href='/privacy-policy'>Privacy Policy</a></li>   
        </ul>

       </div> : null}
       
       </span> 
       
       <div className='menuBtns'>
        { MenuOpen ? <span onClick={()=> menuCloseHandler(false)}><CloseIcon sx={{fontSize: '30px'}}/></span> : <span onClick={()=> menuOpenHandler(true)}><MenuIcon sx={{fontSize: '30px'}}/></span>}
       
       </div>
        </div>
      </header>
      { MenuOpen ? <span className='menuListMobile'>
       <ul>
       <li>
       
       <input onKeyDown={SearchYahoo} className='searchYahooInput' type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' /> 
       </li>
       <li>
       <Link to='/dashboard'>Dashboard</Link>
       </li>
       <li>
       <Link to='/feature'>Features</Link>
       </li>
       {isUserloggedIn ? <li onClick={()=> logoutHandler(true)} style={{cursor: 'pointer'}}>
        Logout
        </li> : <span><li>
        <Link to='/auth/login'>Login</Link>
        </li>
       <li>
       
       <Link to='/auth/signup'><Button variant="contained" sx={{textTransform: 'inherit', background: '#2791f7'}}>Start for free</Button></Link>
       </li></span>}
       </ul>
       </span> : ''}
       <div className='searchBox text-center mt-2 p-2'>
          <input style={{width: '100%'}} onKeyDown={SearchYahoo} className='searchYahooInput' type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' />
        </div>
    </div>
  )
}

export default Header
